import React, {useState, useEffect} from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid, makeStyles, Typography } from '@material-ui/core'
import { generalTextColor } from '../../utitlities/Configurations'; 
import LinkButton from '../General/Buttons/Link';
import NextIcon from '@material-ui/icons/NavigateNext'
import PrevIcon from '@material-ui/icons/NavigateBefore'
import SectionWrapper from '../General/SectionWrapper';
import QuoteLeft from '../General/SocialIcons/QuoteLeft'
import VideoComponent from '../General/VideoComponent'
const useStyles = makeStyles(theme => ({
    container: {
        position: 'relative'
    },
    shape: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        zIndex: -1,
        width: "100%"
    },
    quote: {
        width: "30px",
        '& svg': {
            fill: theme.palette.primary.main
        }
    },
    avatar: {
        maxWidth: "100%",
        // width: "4rem",
        // height: '4rem',
        // borderRadius: '50%',
        overflow: 'hidden',
        margin: "5px",
        [theme.breakpoints.up('sm')]: {
            width: "7rem",
            // height: '5rem',
        },
        [theme.breakpoints.up('md')]: {
            width: "7rem",
            // height: '7rem',
        },
        [theme.breakpoints.down('xs')]: {
            width: "7rem",
            // height: '7rem',
        }
    }
}))


function MarketingTestimonyCard(props) {
    const { testimony, name, designation, imageUrl } = props
    const classes = useStyles()
    return <Grid container spacing={2}>
        <Grid item>
            <div className={classes.quote}>
                <QuoteLeft />
            </div>
        </Grid>
        <Grid item xs={12}>
            <Typography variant='body1' style={{
                color: generalTextColor
            }}>{testimony}</Typography>
        </Grid>
        <Grid item xs={12}>
            <Grid container alignItems='center' spacing={2}>
                <Grid item>
                    {/* <Avatar src={image} /> */}
                    <img src={imageUrl}  alt={name} className={classes.avatar} width= '100%' objectFit= 'contain'  />
                     
                </Grid>
                <Grid item>
                    <Grid container >
                        <Grid item xs={12}>
                            <Typography variant='body1' style={{
                                color: generalTextColor,
                                fontWeight: 600
                            }}>{name}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant='body2' style={{
                                color: generalTextColor
                            }}>{designation}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}

export default function MarketingTestimonials({ 
    marketingTestimonials = [], marketingTestimonialVideo = null}) {

    const testimonials = marketingTestimonials
    const sliderRefM = React.useRef(null)
    const settings = {
        dots: false,
        infinite: true,
        autoplay: false,
        swipeToSlide: true,
        arrows: false,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
              },
            }
          ]
    }
    
    return <SectionWrapper sectionId='marketingTestimonials'>
        <Grid container alignItems='center' spacing={4}>
            <Grid item xs={12} md={6} sm={6}>
                {
                    marketingTestimonialVideo &&
                    <Grid item xs={12}>
                        <VideoComponent url={marketingTestimonialVideo} title='testimonials' />
                    </Grid>
                } 
            </Grid>
            <Grid item xs={12} md={6} sm={6}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{ textTransform: 'uppercase', fontWeight: 600 }} color='secondary' variant="body1"  >
                                    Testimonials
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='h2' color='primary'>
                                    Our Clients Who Speak For Our <Typography variant='h2' component='span' color='secondary'>Superpower</Typography>
                                </Typography>
                            </Grid>
                        </Grid>

                    </Grid>
                    <Grid item xs={12}>
                        <Slider ref={sliderRefM} {...settings}
                            style={{
                                width: '100%'
                            }}
                        >
                            {
                                testimonials.map(item => {
                                    return <MarketingTestimonyCard {...item} imageUrl={item.photo_url}   />
                                })
                            }
                        </Slider>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={2} alignItems='center'>
                            <Grid item>
                                <LinkButton
                                    label='Previous'
                                    buttonProps={{
                                        id: "prevMarketing",
                                        startIcon: <PrevIcon color='secondary' fontSize='small' />,
                                        onClick: () => {
                                            if (!!sliderRefM.current) {
                                                sliderRefM.current.slickPrev()
                                            }
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <LinkButton
                                    label='Next'
                                    buttonProps={{
                                        id: "nextMarketing",
                                        endIcon: <NextIcon color='secondary' fontSize='small' />,
                                        onClick: () => {
                                            if (!!sliderRefM.current) {
                                                sliderRefM.current.slickNext()
                                            }
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>            
        </Grid>
    </SectionWrapper>
}