import React from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
    div: {
        color: theme.palette.primary.main,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 600,
        '& *': {
            margin: "0px"
        },
        '& h3': {
            fontSize: '1.25rem',
            marginTop: '2rem',
            fontWeight: 700,
            lineHeight: 1.5,
            marginBottom: '2rem'
        },
        '& h2': {
            fontSize: theme.typography.h2.fontSize,
            lineHeight: theme.typography.h2.lineHeight,
            marginTop: "2rem",
            fontWeight: 700,
            marginBottom: '2rem'
        },
        '& p': {
            fontSize: theme.typography.body1.fontSize,
            lineHeight: "1.8rem",
            marginBottom: '2rem'
        },
        '& ul': {
            margin: "0px 0px 1rem",
        },
        '& li': {
            padding: "0px 0px 1rem",
            fontSize: theme.typography.body1.fontSize,
            lineHeight: theme.typography.body1.lineHeight,
        }
    }
}))

export default function RichTextComponent({ text, className, styles = {} }) {
    const classes = useStyles()
    return <div className={clsx(classes.div, className)} style={styles} dangerouslySetInnerHTML={{ __html: text }}></div>
}