import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
const useStyles = makeStyles(theme => ({
    button: {
        fontSize: "0.875rem"
    }
}))

export default function LinkButton({ label, buttonProps = {} }) {
    const classes = useStyles()
    return <Button
        variant='text'
        color='secondary'
        disableElevation
        className={classes.button}
        fullWidth={false}
        {...buttonProps}
    >
        {label}
    </Button>
}